import React, { useEffect } from "react";
import Layout, { PageWrapper } from "../../components/layout";
import { PageSection } from "../../styles/index/_components";
import Human from "../../components/people/Human";
import { StaticImage } from "gatsby-plugin-image";
import "twin.macro";
import { cleanUpZnanyLekarz, loadZnanyLekarz } from "../../utils/utils";
import Enumeration from "../../components/text/enumeration";

const MaciejText = {
  name: "Maciej",
  surname: "Schmidt",
  phone: ["507", "970", "598"],
  desc1:
    "Jestem dyplomowanym psychologiem oraz seksuologiem, który ukończył studia psychologiczne oraz psychoseksuologiczne na Uniwersytecie Humanistycznospołecznym SWPS w Sopocie. Obecnie pełnię także rolę wykładowcy na wydziale psychologii klinicznej tego samego uniwersytetu oraz wspomagam funkcjonowanie Koła Naukowego Seksuologii Klinicznej, z którym to organizuję wspólnie różne wydarzenia, między innymi międzynarodowe konferencje naukowe dotyczące seksualności człowieka.\n",
  desc2:
    "Ukończyłem wiele kursów z zakresu seksuologii oraz brałem udział w wielu konferencjach z zakresu seksuologii klinicznej oraz sądowej jako słuchacz, a także jako uczestnik aktywny, przedstawiając swoje badania z zakresu seksualności człowieka. Obecnie prowadzę także badania dotyczące adaptacji do warunków polskich nowoczesnego narzędzia diagnostycznego, służącego do oceny ryzyka recydywy przestępców seksualnych.\n",
  desc3:
    "Szkolenia oraz konferencje w jakich brałem udział dotyczyły głównie zagadnień terapii poznawczo-behawioralnej, płodności i zdrowia psychoseksualnego par, norm oraz patologii rozwoju i funkcjonowania seksualnego, opiniowania sądowo-seksuologicznego, a także pracy z dzieckiem wykorzystywanym seksualnie. Szczególnie interesuję się przestępczością seksualną, zaburzeniami i dysfunkcjami seksualnymi, opiniowaniem sądowo seksuologicznym, procesem korekty płci oraz zaburzeniami afektywnymi takimi jak depresja wśród młodzieży. Także problemy młodzieży jak i osób dorosłych w zakresie orientacji seksualnej czy tożsamości płciowej, są mi w pracy psychoseksuologicznej bliskie. Jestem użytkownikiem wielu grup społecznościowych w internecie, w których to wspieram młodzież oraz dorosłych w zrozumieniu funkcjonowania swojej seksualności. Moje wykształcenie pozwala mi także na prowadzenie kompleksowej edukacji seksualnej. Posiadam również przygotowanie pedagogiczne w rozumieniu Rozporządzenia Ministra Edukacji Narodowej z dnia 12 marca 2009 r.\n",
  desc4:
    "Swoje doświadczenie zawodowe zdobywałem zarówno prowadząc pomoc psychologiczną i seksuologiczną w zakresie własnej praktyki, oraz w takich miejscach jak Poradnia Seksuologiczna i Patologii Współżycia w Warszawie, Szpital dla Nerwowo i Psychicznie Chorych im. Stanisława Kryzana w Starogardzie Gdańskim oraz Poradnia Seksuologiczna i Psychoterapii INTIMED – Zakład Seksuologii Klinicznej w Gdyni.\n",
};

const Maciej = () => {
  useEffect(() => {
    cleanUpZnanyLekarz(document, "zl-widget-s");
    loadZnanyLekarz(document, "script", "zl-widget-s");
  });

  return (
    <Layout>
      <PageSection>
        <PageWrapper>
          <div tw="grid grid-flow-row md:grid-flow-col md:grid-cols-2 items-start">
            <StaticImage
              alt={"human picture"}
              src={"../../images/people/maciej.JPG"}
              placeholder="blurred"
              layout="fullWidth"
            />
            <Human human={MaciejText} />
          </div>
          <div>
            <Enumeration
              title={"Zakres usług:"}
              items={[
                "Diagnostyka psychologiczna i seksuologiczna",
                "Poradnictwo psychologiczne oraz seksuologiczne",
                "Badania psychotestami",
                "Wsparcie młodzieży i rodziców w zakresie psychoedukacyjnym, psychologicznym i seksuologicznym.",
                "Wykonanie opinii psychologicznej lub seksuologicznej",
                "Wydawanie zaświadczeń do instytucji i służb",
              ]}
            />
            <Enumeration
              title={"Pomoc psychologiczna w zakresie:"}
              items={[
                "Zaburzeń afektywnych (depresja)",
                "Zaburzeń osobowości",
                "Zaburzeń odżywiania",
                "Interwencji kryzysowej",
                "Wsparcia w problemach bieżących",
              ]}
            />
            <Enumeration
              title={"Pomoc seksuologiczna w zakresie:"}
              items={[
                "Opinii dla osób transpłciowych, w celu procedowania działań afirmujących płeć",
                "Problemów z akceptacją czy też definiowaniem swojej orientacji seksualnej lub tożsamości płciowej",
                "Dysfunkcji seksualnych (zaburzenia erekcji, wytrysku, popędu seksualnego u kobiet i mężczyzn)",
                "Poczucia uzależnienia od czynności seksualnych",
                "Zaburzeń preferencji seksualnych (również pedofilia)",
                "Psychoedukacji młodzieży oraz rodziców",
                "Edukacja seksualna",
              ]}
            />
          </div>

          <div>
            <a
              id="zl-url"
              className="zl-url"
              href="https://www.znanylekarz.pl/maciej-schmidt/psycholog-seksuolog/gdansk"
              rel="nofollow"
              data-zlw-doctor="maciej-schmidt"
              data-zlw-type="big_with_calendar"
              data-zlw-opinion="false"
              data-zlw-hide-branding="true"
            >
              Maciej Schmidt - ZnanyLekarz.pl
            </a>
          </div>
        </PageWrapper>
      </PageSection>
    </Layout>
  );
};

export default Maciej;
